<template>
  <v-sheet class="pa-4" :class="{ 'grey lighten-4': !darkmode }">
    <Student />
    <Employee class="my-10" is-teacher />
    <Employee class="mb-10" />
    <!-- <Information /> -->
  </v-sheet>
</template>

<script>
export default {
  metaInfo: {
    title: "Dashboard",
    titleTemplate: `%s | ${process.env.VUE_APP_APPLICATION_NAME}`
  },
  components: {
    Student: () => import("./components/Student"),
    Employee: () => import("./components/Employee")
    // Information: () => import("./components/Information")
  },
  computed: {
    darkmode() {
      return this.$store.getters.g_darkmode;
    }
  }
};
</script>
